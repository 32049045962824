import React, { useState, useEffect } from "react";
import loadable from "@loadable/component";

import App from "../App";
import firebaseConfig from "./config";

//const sleep = (m) => new Promise((r) => setTimeout(r, m));

const lazyLoadFirebase = async () => {
  await import("firebase/app").then(async (firebase) => {
    global.firebase = firebase;
    firebase.initializeApp(firebaseConfig);
    if (process.env.NODE_ENV === "production") await import("firebase/analytics").then(() => firebase.analytics());
    await import("firebase/firestore").then(() => firebase.firestore());
    await import("firebase/performance").then(() => firebase.performance());
    await import("firebase/auth").then(() => {});
    try {
      await import("firebase/messaging").then(() =>
        firebase
          .messaging()
          .usePublicVapidKey("BLlZ3UP8MowL5haTFs_5ujcDR8hTkebU40xquD07441zDcXjNAfVcAPoqotVnadeagkT_JCbP6Pwq73pD0MgsJc")
      );
    } catch (e) {
      console.error(e);
    }
  });

  return () => <App />;
};

const LoadingInComponent = ({ delay }) => {
  const [pastDelay, setPastdelay] = useState(false);

  useEffect(() => {
    const tid = setTimeout(() => {
      setPastdelay(true);
    }, delay);
    return () => clearTimeout(tid);
  }, [delay]);

  return pastDelay ? (
    <div
      style={{
        height: "100%",
        backgroundColor: "#f3c622",
        backgroundImage: "url(/static/svg/logo.svg)",
        backgroundPosition: "center",
        backgroundSize: "15%",
        backgroundRepeat: "no-repeat",
      }}
    ></div>
  ) : (
    <></>
  );
};

const LoadableComponent = loadable(lazyLoadFirebase, {
  fallback: <LoadingInComponent delay={500} />,
});

const Component = () => <LoadableComponent />;

export default Component;
