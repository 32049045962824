import React, { useState, useEffect } from "react";

const Loading = ({ delay = 500 }) => {
  const [pastDelay, setPastdelay] = useState(false);

  useEffect(() => {
    const tid = setTimeout(() => {
      setPastdelay(true);
    }, delay);
    return () => clearTimeout(tid);
  }, [delay]);
  return (
    <>
      {pastDelay ? (
        <>
          <div>Loading...</div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default Loading;
