import firebase from "firebase/app";

export const getById = async (collectionRef, id) => {
  try {
    return await collectionRef
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) return docToObject(doc);
        else throw new Error("no doc");
      })
      .catch((error) => {
        console.error(error);
        return {};
      });
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const searchExact = async (collectionRef, search, limit = 10) => {
  const q = collectionRef.where(search.name, "==", search.value).limit(limit);

  return await q
    .get()
    .then((snapshot) => {
      if (snapshot.empty) return [];
      return docsToObjects(snapshot.docs);
    })
    .catch((error) => {
      console.error(error);
      return undefined;
    });
};

export const searchStartsWith = async (collectionRef, search, limit = 10) => {
  return await collectionRef
    .where(search.name, ">=", search.value)
    .where(
      search.name,
      "<",
      search.value.replace(/.$/, (c) => String.fromCharCode(c.charCodeAt(0) + 1))
    )
    .limit(limit)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) return [];
      return docsToObjects(snapshot.docs);
    })
    .catch((error) => {
      console.error(error);
      return undefined;
    });
};

export const create = async (collectionRef, data) => {
  if (data.userId === undefined && firebase.auth().currentUser !== null) data.userId = firebase.auth().currentUser.uid;
  return await collectionRef
    .add({
      ...data,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then((docRef) => docRef.id)
    .catch((error) => {
      console.error(error);
      return undefined;
    });
};

export const update = async (collectionRef, docId, data, merge) => {
  return await collectionRef
    .doc(docId)
    .set(
      {
        ...data,
        userId: firebase.auth().currentUser.uid,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge }
    )
    .then(() => docId)
    .catch((error) => {
      console.error(error);
      return undefined;
    });
};

export const docToObject = (doc) => {
  if (process.env.NODE_ENV !== "production") console.log("got", doc.data());
  return { [doc.id]: { ...doc.data(), id: doc.id } };
};
export const docsToObjects = (docs) => {
  return docs.reduce((map, doc) => {
    return { ...map, ...docToObject(doc) };
  }, {});
};
