import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";

import PrivateRoute from "./PrivateRoute";
import RouteWithTransition from "./RouteWithTransition";
import { history } from "../store";
import stylesDef from "../styles";

import Header from "../views/components/Header";
import Home from "../views/loadable/common/Home";
import Login from "../views/loadable/common/Login";
import EmailAction from "../views/loadable/common/EmailAction";
import LostPassword from "../views/loadable/common/LostPassword";
import ResetPassword from "../views/loadable/common/ResetPassword";

import StudentCompanySearch from "../views/loadable/students/companies/Search";
import StudentCompanyView from "../views/loadable/students/companies/View";
import StudentOfferSearch from "../views/loadable/students/offers/BasicSearch";
import StudentOfferGeoSearch from "../views/loadable/students/offers/GeoSearch";
import StudentOfferRecommended from "../views/loadable/students/offers/Recommended";
import StudentOfferView from "../views/loadable/students/offers/View";
import StudentOfferApply from "../views/loadable/students/offers/Apply";
import StudentApplications from "../views/loadable/students/offers/MyApplications";
import StudentOfferApplication from "../views/loadable/students/offers/Application";
import StudentProfile from "../views/loadable/students/Profile";
import StudentRegister from "../views/loadable/students/Register";

import EnterpriseOfferList from "../views/loadable/enterprise/offers/Manage";
import EnterpriseOfferCreate from "../views/loadable/enterprise/offers/Create";
import EnterpriseOfferEdit from "../views/loadable/enterprise/offers/Edit";
import EnterpriseOfferView from "../views/loadable/enterprise/offers/View";
import EnterpriseOfferApplicationView from "../views/loadable/enterprise/offers/Application";
import EnterpriseCompany from "../views/loadable/enterprise/Company";
import EnterpriseProfile from "../views/loadable/enterprise/Profile";
import EnterpriseRegister from "../views/loadable/enterprise/Register";

const useStyles = makeStyles(stylesDef);

export default function Router() {
  const classes = useStyles();
  return (
    <ConnectedRouter history={history}>
      <div style={{ padding: 12 }}>
        <Header />
        <div className={classes.toolbar} />
        <div className={classes.toolbar} />
        <div className={classes.globalToolbar}>
          <Container maxWidth="lg" className={classes.container}>
            {/* Common */}
            <RouteWithTransition path="/" exact component={Home} />
            <RouteWithTransition path="/emailAction" exact component={EmailAction} />
            <RouteWithTransition path="/lostpassword" exact component={LostPassword} />
            <RouteWithTransition path="/resetPassword" exact component={ResetPassword} />
            <RouteWithTransition path="/login" exact component={Login} />
            <RouteWithTransition path="/s/register" exact component={StudentRegister} />
            <RouteWithTransition path="/e/register" exact component={EnterpriseRegister} />
            {/* Students */}
            <PrivateRoute type="STUDENT" path="/s/profile" exact component={StudentProfile} />
            <PrivateRoute type="STUDENT" path="/s/companies" exact component={StudentCompanySearch} />
            <PrivateRoute type="STUDENT" path="/s/companies/view" exact component={StudentCompanyView} />
            <PrivateRoute type="STUDENT" path="/s/geooffers" exact component={StudentOfferGeoSearch} />
            <PrivateRoute type="STUDENT" path="/s/offers" exact component={StudentOfferSearch} />
            <PrivateRoute type="STUDENT" path="/s/offers/view" exact component={StudentOfferView} />
            <PrivateRoute type="STUDENT" path="/s/offers/recommended" exact component={StudentOfferRecommended} />
            <PrivateRoute type="STUDENT" path="/s/offers/apply" exact component={StudentOfferApply} />
            <PrivateRoute type="STUDENT" path="/s/application" exact component={StudentOfferApplication} />
            <PrivateRoute type="STUDENT" path="/s/applications" exact component={StudentApplications} />
            {/* Companies */}
            <PrivateRoute type="ENTERPRISE" path="/e/offers" exact component={EnterpriseOfferList} />
            <PrivateRoute type="ENTERPRISE" path="/e/offers/create" exact component={EnterpriseOfferCreate} />
            <PrivateRoute type="ENTERPRISE" path="/e/offers/edit" exact component={EnterpriseOfferEdit} />
            <PrivateRoute type="ENTERPRISE" path="/e/offers/view" exact component={EnterpriseOfferView} />
            <PrivateRoute
              type="ENTERPRISE"
              path="/e/offers/application"
              exact
              component={EnterpriseOfferApplicationView}
            />
            <PrivateRoute type="ENTERPRISE" path="/e/company" exact component={EnterpriseCompany} />
            <PrivateRoute type="ENTERPRISE" path="/e/profile" exact component={EnterpriseProfile} />
          </Container>
        </div>
      </div>
    </ConnectedRouter>
  );
}
