import React, { useEffect, useState } from "react";

import { checkFirebaseAuth } from "../actions";
import { useDispatch, useSelector } from "react-redux";

export default () => {
  const dispatch = useDispatch();
  const location = useSelector(state => state.router.location);
  const [pathname] = useState(location.pathname + (location.search || ""));
  useEffect(() => {
    checkFirebaseAuth(dispatch, pathname);
  }, [dispatch, pathname]);

  return <></>;
};
