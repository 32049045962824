import { createMuiTheme } from "@material-ui/core/styles";

export const primaryColor = "#f3c622"; //"#00bcd4";
export const secondaryColor = "#3a3637"; //"#4c4981";
const drawerWidth = 300;

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
});

export const stylesDef = (theme) => ({
  toolbar: theme.mixins.toolbar,
  globalToolbar: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      ...theme.mixins.toolbar,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "2%",
    },
  },
  paper: {
    padding: theme.spacing(3, 2),
    color: "secondary",
  },
  container: {
    position: "relative",
    padding: "2px",
  },
  transitionGroup: {
    position: "relative",
  },
  "transition-page": { position: "absolute" },
  "transition-page-enter": {
    opacity: 0,
    transform: "scale(1.2)",
  },
  "transition-page-enter-active": {
    opacity: 1,
    transform: "scale(1)",
    transition: "opacity 300ms, transform 300ms",
  },
  "transition-page-exit": {
    opacity: 1,
    transform: "scale(1)",
  },
  "transition-page-exit-active": {
    opacity: 0,
    transform: "scale(0.9)",
    transition: "opacity 300ms, transform 300ms",
  },
  grid: {
    //  width: "calc(100%)",
    //  margin: "-16px!important"
  },
  title: {
    flexGrow: 1,
    width: "50vw",
  },
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    flexGrow: 1,
  },
  list: {
    width: "100%",
    maxWidth: "80%",
    backgroundColor: theme.palette.background.paper,
    top: theme.spacing(2),
  },
  card: {
    maxWidth: 500,
    maxHeight: 300,
  },
  media: {
    height: 200,
  },
  description: {
    paddingLeft: "5%",
    maxWidth: 1100,
  },
  modal: {
    position: "absolute",
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    maxwidth: "1",
    flexGrow: 1,
  },
  formControl: {
    margin: `${theme.spacing(1)}px`,
    width: `calc(100% - 1px)`,
    minWidth: "11vw",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  subTitle: {
    flexGrow: 1,
  },
  mapContainer: {
    //overflow: "visible",
    width: "100%",
    height: "50vh",
    //position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  mapMarker: {
    display: "block",
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
    padding: 0,
  },
});
export default stylesDef;
