import firebase from "firebase/app";
//import "firebase/auth";

// CHANGE EMAIL
export const changeEmail = async (newEmail, currentPassword) => {
  // TODO : update md5 in the store and in the db
  const user = firebase.auth().currentUser;
  const credential = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
  const reauth = await user
    .reauthenticateWithCredential(credential)
    .then(() => true)
    .catch(() => false);
  if (reauth !== true) {
    throw new Error("can't reauth");
  } else {
    await firebase.auth().currentUser.updateEmail(newEmail);
    return true;
  }
};

// CHANGE PASSWORD
export const changePasswordKnownCurrent = async (currentPassword, newPassword, newPasswordConfirmation) => {
  if (newPassword !== newPasswordConfirmation) {
    return false;
  }
  const user = firebase.auth().currentUser;
  const credential = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
  const reauth = await user
    .reauthenticateWithCredential(credential)
    .then(() => true)
    .catch(() => false);
  if (reauth !== true) {
    throw new Error("can't reauth");
  } else {
    await user.updatePassword(newPassword);
    return true;
  }
};

export const changePasswordLostPassword = async (code, newPassword) => {
  await firebase.auth().confirmPasswordReset(code, newPassword);
};
