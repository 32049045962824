import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { secondaryColor } from "../../styles";
import { useAddToHomescreenPrompt } from "../../hooks/useAddToHomescreenPrompt";
import { useNotificationsPrompt } from "../../hooks/useNotificationsPrompt";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import Link from "@material-ui/core/Link";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MenuIcon from "@material-ui/icons/Menu";
import MailIcon from "@material-ui/icons/Mail";
import BusinessIcon from "@material-ui/icons/Business";
import SchoolIcon from "@material-ui/icons/School";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import FlareIcon from "@material-ui/icons/Flare";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import UpdateIcon from "@material-ui/icons/Update";
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen";
import NotificationsIcon from "@material-ui/icons/Notifications";

import { logout } from "../../actions";
import Logo from "./svg/logo";
import Modal from "./Modal";

const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appName: {
    textTransform: "lowercase",
    color: secondaryColor,
    fontSize: "1.75rem",
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.02em",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  hide: {
    display: "none",
  },
  logo: {
    width: 32,
    height: 32,
    marginRight: 5,
  },
  drawerShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("lg")]: {
      width: theme.spacing(9) + 1,
    },
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    backgroundColor: "primary",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  avatar: {
    margin: "0px",
    width: "24px",
    height: "24px",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  list: {
    padding: 0,
  },
}));

const PWAPrompt = () => {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isVisible, setVisibleState] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (prompt) {
      setVisibleState(true);
    }
  }, [prompt]);

  return isVisible ? (
    <div>
      <IconButton
        color="inherit"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <AddToHomeScreenIcon />
      </IconButton>
      <Modal
        modalTitle="Installer"
        modalText={
          <>
            Installer l'application jobeez ?
            <br />
            Elle vous permettra d'accéder directement à tous les jobs !
          </>
        }
        open={modalOpen}
        setOpen={setModalOpen}
        confirmAction={async () => {
          setModalOpen(false);
          setVisibleState(false);
          promptToInstall();
        }}
      />
    </div>
  ) : (
    <></>
  );
};

const NotificationsPrompt = () => {
  const [prompt, promptToInstall] = useNotificationsPrompt();
  const [isVisible, setVisibleState] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (prompt) {
      setVisibleState(true);
    }
  }, [prompt]);

  return isVisible ? (
    <div>
      <IconButton
        color="inherit"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <NotificationsIcon />
      </IconButton>
      <Modal
        modalTitle="Notifications"
        modalText={
          <>
            Accepter les notifications ?
            <br />
            Recevez des alertes pour vos candidatures !
          </>
        }
        open={modalOpen}
        setOpen={setModalOpen}
        confirmAction={async () => {
          setModalOpen(false);
          setVisibleState(false);
          promptToInstall();
        }}
      />
    </div>
  ) : (
    <></>
  );
};

const UpdatePrompt = () => {
  const swState = useSelector((state) => state.serviceWorker);

  const updateServiceWorker = () => {
    const registrationWaiting = swState.registration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  return (
    <>
      {swState.updateAvailable && (
        <>
          <Hidden mdDown implementation="css">
            <Typography color="secondary">Mise à jour disponible</Typography>
          </Hidden>
          <IconButton color="inherit" aria-label="open drawer" onClick={updateServiceWorker}>
            <UpdateIcon />
          </IconButton>
        </>
      )}
    </>
  );
};

export default function Header() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();

  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const userType = useSelector((state) => (state.auth.profile ? state.auth.profile.type : undefined));
  const md5 = useSelector((state) => (state.auth.profile ? state.auth.profile.md5 : ""));

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerMobileToggle = () => setMobileOpen(!mobileOpen);

  const HeaderListItem = ({ item, className }) => (
    <li key={item.k}>
      <ListItem
        className={className}
        button
        component={RLink}
        to={item.l ? item.l : ""}
        onClick={() => {
          if (item.a) item.a();
          setMobileOpen(false);
        }}
      >
        <ListItemIcon>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            {item.i && <item.i />}
            {item.avatar && <Avatar className={classes.avatar} src={item.avatar} />}
          </IconButton>
        </ListItemIcon>
        <ListItemText primary={item.t} />
      </ListItem>
    </li>
  );

  const HeaderListItemWithSubList = ({ item, className }) => {
    const [openSubList, setOpenSubList] = useState(false);

    const handleClick = () => {
      setOpenSubList(!openSubList);
    };

    return (
      <>
        <ListItem className={className} button onClick={handleClick}>
          <ListItemIcon>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              {item.i && <item.i />}
              {item.avatar && <Avatar className={classes.avatar} src={item.avatar} />}
            </IconButton>
          </ListItemIcon>
          <ListItemText primary={item.t} />

          {openSubList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openSubList} timeout="auto" unmountOnExit>
          <HeaderList itemClassName={classes.nested} items={item.sublist} />
        </Collapse>
      </>
    );
  };

  const HeaderList = ({ items, itemClassName }) => (
    <List className={classes.list}>
      {items.map((i) =>
        i.sublist ? (
          <HeaderListItemWithSubList className={itemClassName} key={i.k} item={i} />
        ) : (
          <HeaderListItem className={itemClassName} key={i.k} item={i} />
        )
      )}
    </List>
  );

  const appbar = (
    <Toolbar>
      <Hidden lgUp implementation="css">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={useMediaQuery(theme.breakpoints.up("lg")) ? () => {} : handleDrawerMobileToggle}
          edge="start"
          className={clsx(classes.menuButton, { [classes.hide]: mobileOpen })}
        >
          <MenuIcon />
        </IconButton>
      </Hidden>

      <div className={classes.logo}>
        <Logo />
      </div>
      <Link to="/" component={RLink}>
        <Typography variant="h6" className={classes.appName}>
          jobeez
        </Typography>
      </Link>
      <div className={classes.grow} />
      {loggedIn && <PWAPrompt />}
      {loggedIn && <NotificationsPrompt />}
      <UpdatePrompt />
    </Toolbar>
  );

  const drawer = (
    <>
      <div className={classes.toolbar}>
        <Hidden lgUp implementation="css">
          <Typography variant="h6" className={classes.appName}>
            jobeez
            <IconButton
              onClick={useMediaQuery(theme.breakpoints.up("lg")) ? () => {} : handleDrawerMobileToggle}
              aria-label="Menu"
            >
              <ChevronLeftIcon />
            </IconButton>
          </Typography>
        </Hidden>
      </div>
      <HeaderList items={[{ k: "home", t: "Accueil", l: "/", i: HomeIcon }]} />
      {loggedIn && (
        <>
          <Divider />
          {userType === "STUDENT" && (
            <HeaderList
              items={[
                {
                  k: "s_offers",
                  t: "Jobs",
                  i: MailIcon,
                  sublist: [
                    {
                      k: "s_offers_specials",
                      t: "Recommandés",
                      l: "/s/offers/recommended",
                      i: FlareIcon,
                    },
                    { k: "s_offers_search", t: "Recherche basique", l: "/s/offers", i: SearchIcon },
                    { k: "s_offers_geosearch", t: "Recherche Géo", l: "/s/geooffers", i: SearchIcon },
                    {
                      k: "s_offers_applications",
                      t: "Mes candidatures",
                      l: "/s/applications",
                      i: ContactMailIcon,
                    },
                  ],
                },
                {
                  k: "s_profile",
                  t: "Profil",
                  l: "/s/profile/",
                  avatar: `https://www.gravatar.com/avatar/${md5}?d=identicon&r=pg`,
                },
              ]}
            />
          )}
          {userType === "ENTERPRISE" && (
            <HeaderList
              items={[
                // { k: "e_students", t: "Etudiants", l: "/e/students/", i: SchoolIcon },
                { k: "e_offers", t: "Offres", l: "/e/offers/", i: MailIcon },
                { k: "e_company", t: "Entreprise", l: "/e/company/", i: BusinessIcon },
                {
                  k: "e_profile",
                  t: "Profil",
                  l: "/e/profile/",
                  avatar: `https://www.gravatar.com/avatar/${md5}?d=identicon&r=pg`,
                },
              ]}
            />
          )}
          <Divider />
          <HeaderList items={[{ k: "logout", t: "Déconnexion", a: () => dispatch(logout(true)), i: ExitToAppIcon }]} />
        </>
      )}
      {!loggedIn && (
        <>
          <Divider />
          <HeaderList items={[{ k: "s_register", t: "Inscription Etudiants", l: "/s/register", i: SchoolIcon }]} />
          <Divider />
          <HeaderList items={[{ k: "login", t: "Connexion", l: "/login", i: LockOpenIcon }]} />
        </>
      )}
    </>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: useMediaQuery(theme.breakpoints.up("lg")),
        })}
      >
        {appbar}
      </AppBar>
      <Hidden lgUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerMobileToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: true,
            [classes.drawerClose]: false,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: true,
              [classes.drawerClose]: false,
            }),
          }}
          open={!mobileOpen}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </div>
  );
}
