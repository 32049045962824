import React from "react";
import { Route } from "react-router";
import CSSTransition from "react-transition-group/CSSTransition";
import { makeStyles } from "@material-ui/core/styles";

import stylesDef from "../styles";

const useStyles = makeStyles(stylesDef);

export default function RouteWithTransition({ path, component: Component, type, ...rest }) {
  const classes = useStyles();
  return (
    <Route key={path} path={path} {...rest}>
      {({ match }) => (
        <CSSTransition
          in={match !== null}
          timeout={300}
          classNames={{
            enter: `${classes["transition-page-enter"]}`,
            enterActive: `${classes["transition-page-enter-active"]}`,
            exit: `${classes["transition-page-exit"]}`,
            exitActive: `${classes["transition-page-exit-active"]}`,
          }}
          key={path}
        >
          <>
            {match && (
              <div key={path} className={classes["transition-page"]}>
                <Component />
              </div>
            )}
          </>
        </CSSTransition>
      )}
    </Route>
  );
}
