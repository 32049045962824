import React from "react";
import ReactDOM from "react-dom";

//import * as serviceWorker from "./serviceWorker";
import FirebaseWrapper from "./firebase/init";

if (process.env.NODE_ENV !== "production") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}

ReactDOM.render(<FirebaseWrapper />, document.getElementById("root"));

// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register({
//   onSuccess: () => store.dispatch({ type: SW_INIT }),
//   onUpdate: (registration) => store.dispatch({ type: SW_UPDATE_AVAILABLE, payload: registration }),
// });
