import firebase from "firebase/app";

const collection = () => firebase.firestore().collection("users");
export const createUser = async (userId, type, md5, firstName, lastName, city, domain, level) => {
  try {
    await collection()
      .doc(userId)
      .set({
        premium: false,
        type,
        md5,
        firstName,
        lastName,
        city,
        domain,
        level,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const updateCurrentUserMD5 = async (md5) => {
  const userId = firebase.auth().currentUser.uid;
  await collection().doc(userId).set(
    {
      md5,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    },
    { merge: true }
  );
};

export const updateCurrentUser = async (firstName, lastName, city = "", domain = "", level = "") => {
  const userId = firebase.auth().currentUser.uid;
  await collection().doc(userId).set(
    {
      firstName,
      lastName,
      city,
      domain,
      level,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    },
    { merge: true }
  );
};

export const getUserDocument = async (userId) => {
  return await collection()
    .doc(userId)
    .get()
    .then((docSnapshot) => docSnapshot.data())
    .catch((error) => {
      console.error(error);
      return undefined;
    });
};

export const saveNotificationTokenOfCurrentUser = (token) => {
  const userId = firebase.auth().currentUser.uid;
  collection()
    .doc(userId)
    .update("ntk", firebase.firestore.FieldValue.arrayUnion(token))
    .catch((error) => {
      console.error(error);
    });
};
