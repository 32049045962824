import React from "react";
import { Provider } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";

import store from "./store";
import Router from "./router";
import InitUser from "./store/InitUser";
import { theme } from "./styles";
import * as serviceWorker from "./serviceWorker";

export default function App() {
  return (
    <>
      <Provider store={store}>
        <InitUser />
        <MuiThemeProvider theme={theme}>
          <Router />
        </MuiThemeProvider>
      </Provider>
    </>
  );
}

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => store.dispatch({ type: "SW_INIT" }),
  onUpdate: (registration) => store.dispatch({ type: "SW_UPDATE_AVAILABLE", payload: { registration } }),
});
