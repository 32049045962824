var config = {
  apiKey: "AIzaSyD4bU7gSfMrWKHX0aMgwFxkk41ESstk7BM",
  authDomain: "sjobs-2252.firebaseapp.com",
  databaseURL: "https://sjobs-2252.firebaseio.com",
  projectId: "sjobs-2252",
  storageBucket: "sjobs-2252.appspot.com",
  messagingSenderId: "104665143606",
  appId: "1:104665143606:web:cad6c85879e66ba803fb97",
  measurementId: "G-72RWL62E59"
};
export default config;
