import { push } from "connected-react-router";

import * as types from "../actionTypes";
import * as offerDAO from "../../dao/offer";
import * as companyActionsConstructor from "./company";

/* OFFERS */
// create
export const createOffer = (title, description, domain, level, tags, diffAddr, address = {}, lat = 0, lon = 0) => {
  return async (dispatch, getState) => {
    dispatch(createOfferStarted());
    try {
      const companyId = await getState().auth.profile.companyId;
      let offerId = undefined;
      if (diffAddr === true) {
        offerId = await offerDAO.create(
          companyId,
          title,
          description,
          tags,
          domain,
          level,
          diffAddr,
          address,
          lat,
          lon
        );
      } else {
        const company = await getState().data.companies[companyId];
        offerId = await offerDAO.create(
          companyId,
          title,
          description,
          tags,
          domain,
          level,
          diffAddr,
          company.address,
          company.lat,
          company.lon
        );
      }
      if (offerId !== undefined) {
        dispatch(createOfferDone());
        dispatch(push(`/e/offers?success=true&newOffer=${offerId}`));
      } else {
        dispatch(createOfferFailed("offers.create.noid", new Error()));
      }
    } catch (error) {
      console.error(error);
      dispatch(createOfferFailed("offers.create.daoerr", error));
    }
  };
};

const createOfferStarted = () => {
  return {
    type: types.CREATE_OFFER_START,
  };
};

const createOfferDone = () => {
  return {
    type: types.CREATE_OFFER_END,
    payload: {},
  };
};

const createOfferFailed = (code, error) => {
  return {
    type: types.CREATE_OFFER_FAIL,
    payload: { code, error },
  };
};

// edit
export const editOffer = (offerId, title, description, domain, level, tags, diffAddr, address, lat, lon) => {
  return async (dispatch, getState) => {
    dispatch(editOfferStarted());
    try {
      if (diffAddr === true) {
        await offerDAO.update(offerId, title, description, tags, domain, level, false, diffAddr, address, lat, lon);
      } else {
        const companyId = await getState().auth.profile.companyId;
        const company = await getState().data.companies[companyId];
        await offerDAO.update(
          offerId,
          title,
          description,
          tags,
          domain,
          level,
          false,
          diffAddr,
          company.address,
          company.lat,
          company.lon
        );
      }
      dispatch(editOfferDone());
      dispatch(push(`/e/offers?success=true&editedOffer=${offerId}`));
    } catch (error) {
      console.error(error);
      dispatch(editOfferFailed("offers.edit.daoerr", error));
    }
  };
};

const editOfferStarted = () => {
  return {
    type: types.CREATE_OFFER_START,
  };
};

const editOfferDone = () => {
  return {
    type: types.CREATE_OFFER_END,
    payload: {},
  };
};

const editOfferFailed = (code, error) => {
  return {
    type: types.CREATE_OFFER_FAIL,
    payload: { code, error },
  };
};

function getCompaniesOfOffers(offers) {
  return async (dispatch) => {
    const companiesIdsUnfilt = Object.entries(offers).map(([key, value]) => value.companyId);
    const companiesIds = [...new Set(companiesIdsUnfilt)];
    await Promise.all(
      companiesIds.map(async (companyId) => await dispatch(companyActionsConstructor.getCompanyById(companyId)))
    );
  };
}

export function resetOffersSearch() {
  return (dispatch) => {
    dispatch(resetSearch());
  };
}

const resetSearch = () => {
  return {
    type: types.SEARCH_OFFERS_RESET,
  };
};

// domain and level
export function searchOffersByDomainAndLevel(domain, level, limit = 9) {
  return async (dispatch) => {
    try {
      dispatch(searchOffersStarted());
      let offers =
        level === "" || level === undefined
          ? await offerDAO.searchExact({ name: "domain", value: domain }, limit)
          : await offerDAO.searchDomainLevel(domain, level, limit);
      await dispatch(getCompaniesOfOffers(offers));
      dispatch(searchOffersDone(offers, domain, level));
    } catch (error) {
      dispatch(searchOffersFailed("offers.search.daoerr", error));
    }
  };
}
// search more domain and level
export function moreOffersByDomainAndLevel(domain, level, afterId, limit = 9) {
  return async (dispatch) => {
    try {
      dispatch(searchOffersStarted());
      let offers =
        level === "" || level === undefined
          ? await offerDAO.searchExact({ name: "domain", value: domain }, afterId, limit)
          : await offerDAO.searchDomainLevel(domain, level, afterId, limit);
      await dispatch(getCompaniesOfOffers(offers));
      dispatch(moreOffersDone(offers));
    } catch (error) {
      dispatch(searchOffersFailed("offers.search.daoerr", error));
    }
  };
}
// geo
export function searchOffersByLocation({ lat, lon }, limit = 9) {
  return async (dispatch) => {
    try {
      dispatch(searchOffersStarted());
      let offers = await offerDAO.searchGeo(lat, lon, limit);
      await dispatch(getCompaniesOfOffers(offers));
      dispatch(searchOffersDone(offers));
    } catch (error) {
      console.error(error);
      dispatch(searchOffersFailed("offers.search.daoerr", error));
    }
  };
}

export function searchOffersFull(domain, level, { lat, lon }, limit = 9) {
  return async (dispatch) => {
    try {
      dispatch(searchOffersStarted());
      let offers = await offerDAO.searchGeoDomainLevel({ lat, lon }, domain, level, limit);
      await dispatch(getCompaniesOfOffers(offers));
      dispatch(searchOffersDone(offers, domain, level));
    } catch (error) {
      console.error(error);
      dispatch(searchOffersFailed("offers.search.daoerr", error));
    }
  };
}

// by tag
export function searchOffersByTags(tags, limit = 10) {
  return async (dispatch) => {
    try {
      dispatch(searchOffersStarted());
      let offers = await offerDAO.searchTags(tags, limit);
      await dispatch(getCompaniesOfOffers(offers));
      dispatch(searchOffersDone(offers));
    } catch (error) {
      dispatch(searchOffersFailed("offers.search.daoerr", error));
    }
  };
}
// by title
export function searchOffersByTitle(title, limit = 10) {
  return async (dispatch) => {
    try {
      dispatch(searchOffersStarted());
      let offers = await offerDAO.searchStartsWith({ name: "title", value: title }, limit);
      await dispatch(getCompaniesOfOffers(offers));
      dispatch(searchOffersDone(offers));
    } catch (error) {
      dispatch(searchOffersFailed("offers.search.daoerr", error));
    }
  };
}

// by company
export function searchOffersByCompany(companyId, limit = 10) {
  return async (dispatch) => {
    try {
      dispatch(searchOffersStarted());
      let offers = await offerDAO.searchOffersByCompanyId(companyId, limit);
      await getCompaniesOfOffers(offers);
      dispatch(searchOffersDone(offers));
    } catch (error) {
      dispatch(searchOffersFailed("offers.search.daoerr", error));
    }
  };
}

const searchOffersStarted = () => {
  return {
    type: types.SEARCH_OFFERS_START,
  };
};

const searchOffersDone = (offers, domain, level) => {
  return {
    type: types.SEARCH_OFFERS_END,
    payload: { ids: Object.keys(offers), offers },
  };
};
const moreOffersDone = (offers) => {
  return {
    type: types.MORE_OFFERS_END,
    payload: { ids: Object.keys(offers), offers },
  };
};

const searchOffersFailed = (code, error) => {
  console.error(error);
  return {
    type: types.SEARCH_OFFERS_FAIL,
    payload: { code, error },
  };
};

/* SINGLE OFFER */
// by id
export function getOfferById(id) {
  return async (dispatch, getState) => {
    if (getState().data.offers[id] === undefined)
      try {
        dispatch(getOfferStarted());
        let offer = await offerDAO.getById(id);
        if (offer === undefined) {
          dispatch(getOfferFailed("offers.getbyid.nooffer", new Error()));
        } else {
          dispatch(getOfferDone(offer));
        }
      } catch (error) {
        dispatch(getOfferFailed("offers.getbyid.daoerr", error));
      }
    else console.log("skipping get by id", id);
  };
}

const getOfferStarted = () => {
  return {
    type: types.GET_OFFER_START,
  };
};

const getOfferDone = (offer) => {
  return {
    type: types.GET_OFFER_END,
    payload: { offer },
  };
};

const getOfferFailed = (code, error) => {
  return {
    type: types.GET_OFFER_FAIL,
    payload: { code, error },
  };
};

/* APPLICATION */
// by offer & user
export function getCurrentUserApplicationOfOffer(offerId) {
  return async (dispatch) => {
    try {
      dispatch(searchApplicationsStarted());
      let application = await offerDAO.getApplicationOfCurrentUser(offerId);
      dispatch(searchApplicationsDone(offerId, application));
    } catch (error) {
      dispatch(searchApplicationsFailed("offers.applications.getOfUserAndOffer.daoerr", error));
    }
  };
}
// by offer & user
export function getApplicationsOfOffer(offerId) {
  return async (dispatch) => {
    try {
      dispatch(searchApplicationsStarted());
      let applications = await offerDAO.getApplicationsOfOffer(offerId);
      dispatch(searchApplicationsDone(offerId, applications));
    } catch (error) {
      dispatch(searchApplicationsFailed("offers.applications.getOfOffer.daoerr", error));
    }
  };
}
// by user
export function getApplicationsOfCurrentUser() {
  return async (dispatch) => {
    try {
      dispatch(getUserApplicationsStarted());
      let { applications, offers } = await offerDAO.getApplicationsOfCurrentUser();
      dispatch(searchOffersDone(offers));
      dispatch(getUserApplicationsDone(applications));
    } catch (error) {
      dispatch(getUserApplicationsFailed("offers.applications.getOfUser.daoerr", error));
    }
  };
}
// by ids
export function getApplication(offerId, applicationId) {
  return async (dispatch) => {
    try {
      dispatch(searchApplicationsStarted());
      let application = await offerDAO.getApplication(offerId, applicationId);
      dispatch(searchApplicationsDone(offerId, { [application.id]: application }));
    } catch (error) {
      dispatch(getUserApplicationsFailed("offers.applications.getOfUser.daoerr", error));
    }
  };
}

const searchApplicationsStarted = () => {
  return {
    type: types.SEARCH_APPLICATIONS_START,
  };
};

const searchApplicationsDone = (offerId, applications) => {
  return {
    type: types.SEARCH_APPLICATIONS_END,
    payload: { offerId, applications },
  };
};

const searchApplicationsFailed = (code, error) => {
  return {
    type: types.SEARCH_APPLICATIONS_FAIL,
    payload: { code, error },
  };
};
const getUserApplicationsStarted = () => {
  return {
    type: types.GET_USER_APPLICATIONS_START,
  };
};

const getUserApplicationsDone = (applications) => {
  return {
    type: types.GET_USER_APPLICATIONS_END,
    payload: { applications },
  };
};

const getUserApplicationsFailed = (code, error) => {
  return {
    type: types.GET_USER_APPLICATIONS_FAIL,
    payload: { code, error },
  };
};

export const applyForOffer = (offerId, message) => {
  return async (dispatch) => {
    dispatch(createApplicationStarted());
    try {
      const applicationId = await offerDAO.createApplication(offerId, message);
      if (applicationId !== undefined) {
        dispatch(createApplicationDone());
        dispatch(push(`/s/application?id=${offerId}`));
      } else {
        dispatch(createApplicationFailed("offers.applications.create.noid", new Error()));
      }
    } catch (error) {
      dispatch(createApplicationFailed("offers.applications.create.daoerr", error));
    }
  };
};

const createApplicationStarted = () => {
  return {
    type: types.CREATE_APPLICATION_START,
  };
};

const createApplicationDone = () => {
  return {
    type: types.CREATE_APPLICATION_END,
    payload: {},
  };
};

const createApplicationFailed = (code, error) => {
  return {
    type: types.CREATE_APPLICATION_FAIL,
    payload: { code, error },
  };
};

// TODO : manage errors
export const addComment = (offerId, applicationId, text) => {
  return async (dispatch) => {
    console.log("addComment", offerId, applicationId, text);
    await offerDAO.addComment(offerId, applicationId, text);
    dispatch(getApplication(offerId, applicationId));
  };
};
