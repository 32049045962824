import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createBrowserHistory } from "history";
import createRootReducer from "../reducers";
import { routerMiddleware } from "connected-react-router";

/*
See commented code below
import firebase from "firebase/app";
import "firebase/analytics";
*/

export const initialState = {
  serviceWorker: {
    initialized: false,
    updateAvailable: false,
    updated: false,
    registration: null,
  },
  auth: {
    firebaseAuthLoading: false,
    loggedIn: false,
    profile: {},
    userId: undefined,
    login: {
      inError: false,
      loading: false,
    },
    logout: {
      inError: false,
      loading: false,
    },
    register: {
      inError: false,
      loading: false,
    },
    requestResetPassword: {
      inError: false,
      loading: false,
      done: false,
    },
    resetPassword: {
      inError: false,
      loading: false,
      done: false,
    },
  },
  data: {
    companies: {},
    applicationsByOffer: {},
    offers: {},
    profiles: {},
    searchCompanies: {
      ids: [],
      inError: false,
      loading: false,
    },
    searchOffers: {
      ids: [],
      inError: false,
      loading: false,
    },
    searchProfiles: {
      ids: [],
      inError: false,
      loading: false,
    },
    searchApplications: {
      ids: [],
      inError: false,
      loading: false,
    },
    updateCompany: {
      inError: false,
      loading: false,
    },
    updateProfile: {
      inError: false,
      loading: false,
    },
  },
  error: {
    inError: false,
    code: undefined,
    mesage: undefined,
    type: undefined,
  },
};

export const history = createBrowserHistory();
/*
Seems useless because firebase already registers all page view per default ?
history.listen(location => {
  console.log("hist listen", location);
  if (process.env.NODE_ENV === "production") firebase.analytics().logEvent("page_view"); // Record a pageview for the given page
});
*/
const store = createStore(
  createRootReducer(history),
  { ...initialState },
  applyMiddleware(routerMiddleware(history), thunkMiddleware)
);

export default store;
