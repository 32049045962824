import firebase from "firebase/app";
import { GeoFirestore } from "geofirestore";

import * as commonFirebase from "./commonFirebase";
//import * as geoOfferDao from "./geooffer";

//const collection = () => firebase.firestore().collection("offers");
const collection = () => new GeoFirestore(firebase.firestore()).collection("offers");

const applicationCollection = (offerId) =>
  firebase.firestore().collection("offers").doc(offerId).collection("applications");

export const create = async (companyId, title, description, tags, domain, level, diffAddr, address, lat, lon) => {
  const r = await commonFirebase.create(collection(), {
    applicationsCount: 0,
    companyId,
    title,
    description,
    tags,
    domain,
    level,
    closed: false,
    diffAddr,
    address,
    coordinates: new firebase.firestore.GeoPoint(parseFloat(lat), parseFloat(lon)),
  });
  //if (lat !== undefined && lon !== undefined) await geoOfferDao.create(r, companyId, lat, lon);
  return r;
};

export const update = async (id, title, description, tags, domain, level, closed, diffAddr, address, lat, lon) =>
  await commonFirebase.update(
    collection(),
    id,
    {
      title,
      description,
      tags,
      domain,
      level,
      closed,
      diffAddr,
      address,
      coordinates: new firebase.firestore.GeoPoint(parseFloat(lat), parseFloat(lon)),
    },
    true
  );

export const close = async (id) => await commonFirebase.update(collection(), id, { closed: true });

export const getById = async (id) => await commonFirebase.getById(collection(), id);

export const searchExact = async (search, limit = 10) => await commonFirebase.searchExact(collection(), search, limit);

export const searchOffersByCompanyId = async (companyId) => {
  return await commonFirebase.searchExact(collection(), { name: "companyId", value: companyId }, 1000);
};

export const searchDomainLevel = async (domain, level, limit = 9) => {
  const q = collection().where("domain", "==", domain).where("level", "==", level).limit(limit);
  return await q
    .get()
    .then((snapshot) => {
      if (snapshot.empty) return [];
      return commonFirebase.docsToObjects(snapshot.docs);
    })
    .catch((error) => {
      console.error(error);
      return undefined;
    });
};

export const searchGeo = async (lat, lon, limit) => {
  const docs = await near(lat, lon);
  return commonFirebase.docsToObjects(docs);
};

export const searchGeoDomainLevel = async ({ lat, lon, radius = 10 }, domain, level, limit) => {
  //console.log(lat, lon, radius, domain, level);
  let query = collection();
  if (lat && lon)
    query = query.near({ center: new firebase.firestore.GeoPoint(parseFloat(lat), parseFloat(lon)), radius });
  if (domain) query = query.where("domain", "==", domain);
  if (level) query = query.where("level", "==", level);
  const docs = await query.get().then((value) => value.docs);
  return commonFirebase.docsToObjects(docs);
};

export const near = async (lat, lon, radius = 10) => {
  //console.log("near", lat, lon, radius);
  return await collection()
    .near({ center: new firebase.firestore.GeoPoint(parseFloat(lat), parseFloat(lon)), radius: radius })
    .get()
    .then((value) => value.docs);
};

export const searchTags = async (tags, limit) => {
  return await collection()
    .where("tags", "array-contains-any", tags)
    .limit(limit)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) return [];
      return commonFirebase.docsToObjects(snapshot.docs);
    })
    .catch((error) => {
      console.error(error);
      return undefined;
    });
};

export const searchStartsWith = (search) => commonFirebase.searchStartsWith(collection(), search);

export const createApplication = async (offerId, message) => {
  try {
    const userId = firebase.auth().currentUser.uid;
    return await applicationCollection(offerId)
      .doc(userId)
      .set({
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        userId,
        message,
      })
      .then(() => userId)
      .catch((error) => {
        console.error(error);
        return undefined;
      });
  } catch (error) {
    console.error(error);
  }
};

export const getApplicationOfCurrentUser = async (offerId) =>
  await applicationCollection(offerId)
    .doc(firebase.auth().currentUser.uid)
    .get()
    .then((doc) => (doc.exists ? commonFirebase.docToObject(doc) : undefined))
    .catch((e) => {
      console.error(e);
      return undefined;
    });

export const getApplicationsOfOffer = async (offerId) =>
  await applicationCollection(offerId)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) return [];
      else
        return snapshot.docs.reduce((acc, v) => {
          return { ...acc, ...commonFirebase.docToObject(v) };
        }, {});
    })
    .catch((error) => {
      console.error(error);
      return [];
    });

export const getApplication = async (offerId, applicationId) => {
  return await firebase
    .firestore()
    .collection("offers")
    .doc(offerId)
    .collection("applications")
    .doc(applicationId)
    .get()
    .then((doc) => (doc.exists ? commonFirebase.docToObject(doc) : undefined))
    .catch((error) => {
      console.error(error);
      throw error;
    });
};
export const getApplicationsOfCurrentUser = async () => {
  const userId = firebase.auth().currentUser.uid;
  const applicationsCrossOffers = firebase.firestore().collectionGroup("applications").where("userId", "==", userId);
  return await applicationsCrossOffers
    .get()
    .then(async (snapshot) => {
      if (snapshot.empty) return { applications: [], offers: {} };
      else
        return await snapshot.docs.reduce(
          async (acc, v) => {
            acc = await acc;
            const offerId = v.ref.parent.parent.id;
            acc.applications.push(applicationToObject(v));
            const off = await getById(offerId);
            return {
              applications: acc.applications,
              offers: { ...acc.offers, ...off },
            };
          },
          { applications: [], offers: {} }
        );
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const addComment = async (offerId, applicationId, text) => {
  const userId = firebase.auth().currentUser.uid;
  await applicationCollection(offerId)
    .doc(applicationId)
    .update({
      comments: firebase.firestore.FieldValue.arrayUnion({
        text,
        userId,
        postedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      }),
      lastCommentPostedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then(() => {})
    .catch((e) => {
      console.error(e);
    });
};

const applicationToObject = (doc) => {
  return { [doc.id]: { ...doc.data(), id: doc.id, offerId: doc.ref.parent.parent.id } };
};
