import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Modal = ({
  modalTitle,
  modalText,
  confirmBtnText = "Confirmer",
  open = false,
  setOpen,
  cancelBtnText = "Annuler",
  confirmAction,
}) => {
  const handleCloseModal = () => setOpen(false);
  return (
    <>
      <Dialog
        aria-labelledby="dialog-confirm"
        aria-describedby="dialog-confirm-comment"
        open={open}
        onClose={handleCloseModal}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle id="dialog-title">{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            {cancelBtnText}
          </Button>
          <Button onClick={confirmAction} color="primary">
            {confirmBtnText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Modal;

if (process.env.NODE_ENV !== "production") {
  Modal.whyDidYouRender = {
    logOnDifferentValues: true,
    customName: "Modal",
  };
}
