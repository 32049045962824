export const SCREEN_CHANGED = "SCREEN_CHANGED";

export const CLEAR_GLOBAL_ERROR = "CLEAR_GLOBAL_ERROR";
export const GLOBAL_ERROR = "GLOBAL_ERROR";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_END = "LOGIN_END";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REQUEST_RESET_PASSWORD_START = "REQUEST_RESET_PASSWORD_START";
export const REQUEST_RESET_PASSWORD_END = "REQUEST_RESET_PASSWORD_END";
export const REQUEST_RESET_PASSWORD_FAIL = "REQUEST_RESET_PASSWORD_FAIL";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_END = "RESET_PASSWORD_END";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const MD5_UPDATE = "MD5_UPDATE";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_END = "LOGOUT_END";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const SEARCH_COMPANIES_START = "SEARCH_COMPANIES_START";
export const SEARCH_COMPANIES_END = "SEARCH_COMPANIES_END";
export const SEARCH_COMPANIES_FAIL = "SEARCH_COMPANIES_FAIL";

export const SEARCH_OFFERS_RESET = "SEARCH_OFFERS_RESET";
export const SEARCH_OFFERS_START = "SEARCH_OFFERS_START";
export const SEARCH_OFFERS_END = "SEARCH_OFFERS_END";
export const MORE_OFFERS_END = "MORE_OFFERS_END";
export const SEARCH_OFFERS_FAIL = "SEARCH_OFFERS_FAIL";

export const SEARCH_APPLICATIONS_START = "SEARCH_APPLICATIONS_START";
export const SEARCH_APPLICATIONS_END = "SEARCH_APPLICATIONS_END";
export const SEARCH_APPLICATIONS_FAIL = "SEARCH_APPLICATIONS_FAIL";

export const GET_USER_APPLICATIONS_START = "GET_USER_APPLICATIONS_START";
export const GET_USER_APPLICATIONS_END = "GET_USER_APPLICATIONS_END";
export const GET_USER_APPLICATIONS_FAIL = "GET_USER_APPLICATIONS_FAIL";

export const GET_OFFER_START = "GET_OFFER_START";
export const GET_OFFER_END = "GET_OFFER_END";
export const GET_OFFER_FAIL = "GET_OFFER_FAIL";

export const REGISTER_STUDENT_EMAIL_START = "REGISTER_STUDENT_EMAIL_START";
export const REGISTER_STUDENT_EMAIL_SUCCESS = "REGISTER_STUDENT_EMAIL_SUCCESS";
export const REGISTER_STUDENT_EMAIL_FAIL = "REGISTER_STUDENT_EMAIL_FAIL";

export const CREATE_USER_STUDENT_START = "CREATE_USER_STUDENT_START";
export const CREATE_USER_STUDENT_END = "CREATE_USER_STUDENT_END";

export const FIREBASE_AUTH_START = "FIREBASE_AUTH_START";
export const FIREBASE_AUTH_END = "FIREBASE_AUTH_END";

export const GET_COMPANY_START = "GET_COMPANY_START";
export const GET_COMPANY_END = "GET_COMPANY_END";
export const GET_COMPANY_FAIL = "GET_COMPANY_FAIL";

export const UPDATE_COMPANY_START = "UPDATE_COMPANY_START";
export const UPDATE_COMPANY_END = "UPDATE_COMPANY_END";
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL";

export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_END = "UPDATE_PROFILE_END";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const CREATE_APPLICATION_START = "CREATE_APPLICATION_START";
export const CREATE_APPLICATION_END = "CREATE_APPLICATION_END";
export const CREATE_APPLICATION_FAIL = "CREATE_APPLICATION_FAIL";

export const CREATE_OFFER_START = "CREATE_OFFER_START";
export const CREATE_OFFER_END = "CREATE_OFFER_END";
export const CREATE_OFFER_FAIL = "CREATE_OFFER_FAIL";
