import { initialState } from "../store";

const SW_INIT = "SW_INIT";
const SW_UPDATE_AVAILABLE = "SW_UPDATE_AVAILABLE";
const SW_UPDATED = "SW_UPDATED";

export default (state = initialState.serviceWorker, action) => {
  switch (action.type) {
    case SW_INIT:
      return {
        ...state,
        initialized: true,
      };
    case SW_UPDATE_AVAILABLE:
      return {
        ...state,
        updateAvailable: true,
        updated: false,
        registration: action.payload.registration,
      };
    case SW_UPDATED:
      return { ...state, updateAvailable: false, updated: true, registration: null };
    default:
      return { ...state };
  }
};
