import * as actionTypes from "../actions/actionTypes";
import { initialState } from "../store";

const authReducer = (state = initialState.auth, action) => {
  switch (action.type) {
    /* LOGIN */
    case actionTypes.FIREBASE_AUTH_START:
      return {
        ...state,
        firebaseAuthLoading: true,
      };
    case actionTypes.FIREBASE_AUTH_END:
      return {
        ...state,
        firebaseAuthLoading: false,
      };
    case actionTypes.LOGIN_START:
      return {
        ...state,
        loggedIn: false,
        profile: {},
        userId: undefined,
        login: {
          inError: false,
          loading: true,
        },
      };
    case actionTypes.LOGIN_END:
      return {
        ...state,
        loggedIn: true,
        userId: action.payload.id,
        profile: action.payload.userDocument,
        login: {
          inError: false,
          loading: false,
        },
      };
    case actionTypes.LOGIN_FAIL:
      return {
        ...state,
        loggedIn: false,
        userId: undefined,
        profile: undefined,
        login: {
          inError: true,
          loading: false,
          error: action.payload.error,
          code: action.payload.code,
        },
      };
    case actionTypes.MD5_UPDATE: {
      return {
        ...state,
        profile: { ...state.profile, md5: action.payload.md5 },
      };
    }
    /* LOGOUT */
    case actionTypes.LOGOUT_START:
      return {
        ...state,
        logout: {
          loading: true,
        },
      };
    case actionTypes.LOGOUT_END:
      return {
        ...state,
        loggedIn: false,
        logout: {
          inError: false,
          loading: false,
        },
      };
    case actionTypes.LOGOUT_FAIL:
      return {
        ...state,
        logout: {
          inError: true,
          loading: false,
          error: action.payload.error,
          code: action.payload.code,
        },
      };

    /* REGISTER STUDENT */
    case actionTypes.REGISTER_STUDENT_EMAIL_START:
      return {
        ...state,
        register: {
          inError: false,
          loading: true,
        },
      };
    case actionTypes.REGISTER_STUDENT_EMAIL_SUCCESS:
      return {
        ...state,
        register: {
          inError: false,
          loading: false,
        },
      };
    case actionTypes.REGISTER_STUDENT_EMAIL_FAIL:
      return {
        ...state,
        register: {
          ...state.register,
          inError: true,
          loading: false,
          error: action.payload.error,
          code: action.payload.code,
        },
      };
    /* REQUEST RESET PASSWORD*/
    case actionTypes.REQUEST_RESET_PASSWORD_START:
      return {
        ...state,
        requestResetPassword: {
          inError: false,
          loading: true,
          done: false,
        },
      };
    case actionTypes.REQUEST_RESET_PASSWORD_END:
      return {
        ...state,
        requestResetPassword: {
          inError: false,
          loading: false,
          done: true,
        },
      };
    case actionTypes.REQUEST_RESET_PASSWORD_FAIL:
      return {
        ...state,
        requestResetPassword: {
          inError: true,
          loading: false,
          done: false,
          error: action.payload.error,
          code: action.payload.code,
        },
      }; /*  RESET PASSWORD*/
    case actionTypes.RESET_PASSWORD_START:
      return {
        ...state,
        resetPassword: {
          inError: false,
          loading: true,
          done: false,
        },
      };
    case actionTypes.RESET_PASSWORD_END:
      return {
        ...state,
        resetPassword: {
          inError: false,
          loading: false,
          done: true,
        },
      };
    case actionTypes.RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetPassword: {
          inError: true,
          loading: false,
          done: false,
          error: action.payload.error,
          code: action.payload.code,
        },
      };
    /* UPDATE PROFILE */
    case actionTypes.UPDATE_PROFILE_END:
      return {
        ...state,
        profile: action.payload.userDocument,
      };
    /* - */
    default:
      return { ...state };
  }
};

export default authReducer;
