import * as actionTypes from "../actions/actionTypes";
import { initialState } from "../store";

const dataReducer = (state = initialState.data, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_COMPANIES_START:
      return {
        ...state,
        searchCompanies: {
          loading: true,
          inError: false,
          ids: [],
        },
      };
    case actionTypes.SEARCH_COMPANIES_END:
      return {
        ...state,
        companies: { ...state.companies, ...action.payload.companies },
        searchCompanies: {
          ids: action.payload.ids,
          loading: false,
          inError: false,
        },
      };
    case actionTypes.SEARCH_COMPANIES_FAIL:
      return {
        ...state,
        searchCompanies: {
          ids: [],
          loading: false,
          inError: true,
          error: action.payload.error,
          code: action.payload.code,
        },
      };
    case actionTypes.SEARCH_OFFERS_RESET:
      return {
        ...state,
        searchOffers: {
          done: false,
          loading: false,
          inError: false,
          ids: [],
        },
      };
    case actionTypes.SEARCH_OFFERS_START:
      return {
        ...state,
        searchOffers: {
          done: false,
          loading: true,
          inError: false,
          ids: [],
        },
      };
    case actionTypes.SEARCH_OFFERS_END:
      return {
        ...state,
        offers: { ...state.offers, ...action.payload.offers },
        searchOffers: {
          ids: action.payload.ids,
          done: true,
          loading: false,
          inError: false,
        },
      };
    case actionTypes.MORE_OFFERS_END:
      return {
        ...state,
        offers: { ...state.offers, ...action.payload.offers },
        searchOffers: {
          ids: (state.searchOffers.id ? state.searchOffers.ids : []).concat(action.payload.ids),
          loading: false,
          inError: false,
          currentDomain: action.payload.domain,
          currentLevel: action.payload.level,
        },
      };
    case actionTypes.SEARCH_OFFERS_FAIL:
      return {
        ...state,
        searchOffers: {
          ids: [],
          loading: false,
          inError: true,
          error: action.payload.error,
          code: action.payload.code,
        },
      };
    case actionTypes.SEARCH_APPLICATIONS_START:
      return {
        ...state,
        searchApplications: {
          loading: true,
          inError: false,
          ids: [],
        },
      };
    case actionTypes.SEARCH_APPLICATIONS_END:
      return {
        ...state,
        applicationsByOffer: {
          ...state.applicationsByOffer,
          ...{ [action.payload.offerId]: action.payload.applications },
        },
        searchApplications: {
          ids: action.payload.ids,
          loading: false,
          inError: false,
        },
      };
    case actionTypes.SEARCH_APPLICATIONS_FAIL:
      return {
        ...state,
        searchApplications: {
          ids: [],
          loading: false,
          inError: true,
          error: action.payload.error,
          code: action.payload.code,
        },
      };
    case actionTypes.GET_USER_APPLICATIONS_START:
      return {
        ...state,
        applicationsByUser: [],
        searchApplications: {
          loading: true,
          inError: false,
          ids: [],
        },
      };
    case actionTypes.GET_USER_APPLICATIONS_END:
      return {
        ...state,
        applicationsByUser: action.payload.applications,
        searchApplications: {
          ids: [],
          loading: false,
          inError: false,
        },
      };
    case actionTypes.GET_USER_APPLICATIONS_FAIL:
      return {
        ...state,
        applicationsByUser: [],
        searchApplications: {
          ids: [],
          loading: false,
          inError: true,
          error: action.payload.error,
          code: action.payload.code,
        },
      };
    case actionTypes.GET_OFFER_START:
      return { ...state };
    case actionTypes.GET_OFFER_END:
      return {
        ...state,
        offers: { ...state.offers, ...action.payload.offer },
      };
    case actionTypes.GET_OFFER_FAIL:
      return { ...state };
    case actionTypes.GET_COMPANY_START:
      return { ...state };
    case actionTypes.GET_COMPANY_END:
      return {
        ...state,
        companies: { ...state.companies, ...action.payload.company },
      };
    case actionTypes.GET_COMPANY_FAIL:
      return { ...state };
    case actionTypes.UPDATE_COMPANY_START:
      return { ...state, updateCompany: { inError: false, loading: true } };
    case actionTypes.UPDATE_COMPANY_END:
      return { ...state, updateCompany: { inError: false, loading: false } };
    case actionTypes.UPDATE_COMPANY_FAIL:
      return {
        ...state,
        updateCompany: {
          error: action.payload.error,
          code: action.payload.code,
          inError: true,
          loading: false,
        },
      };
    case actionTypes.UPDATE_PROFILE_START:
      return { ...state, updateProfile: { inError: false, loading: true } };
    case actionTypes.UPDATE_PROFILE_END:
      return { ...state, updateProfile: { inError: false, loading: false } };
    case actionTypes.UPDATE_PROFILE_FAIL:
      return {
        ...state,
        updateProfile: {
          error: action.payload.error,
          code: action.payload.code,
          inError: true,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default dataReducer;
