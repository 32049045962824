import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import dataReducer from "./data";
import errorReducer from "./error";
import authReducer from "./auth";
import serviceWorkerReducer from "./serviceWorker";

const createRootReducer = (history) => {
  return combineReducers({
    data: dataReducer,
    serviceWorker: serviceWorkerReducer,
    error: errorReducer,
    router: connectRouter(history),
    auth: authReducer,
  });
};

export default createRootReducer;
