import * as types from "../actionTypes";
import * as companyDAO from "../../dao/company";

/* SEARCH COMPANIES */
// by name
export function searchCompaniesByName(name, limit = 10) {
  return async (dispatch) => {
    try {
      dispatch(searchCompaniesStarted());
      let companies = await companyDAO.search({ name: "name", value: name }, limit);
      dispatch(searchCompaniesDone(companies));
    } catch (error) {
      console.error(error);
      dispatch(searchCompaniesFailed(error));
    }
  };
}

const searchCompaniesStarted = () => {
  return {
    type: types.SEARCH_COMPANIES_START,
  };
};

const searchCompaniesDone = (companies) => {
  return {
    type: types.SEARCH_COMPANIES_END,
    payload: { ids: Object.keys(companies), companies },
  };
};

const searchCompaniesFailed = (error, code) => {
  return {
    type: types.SEARCH_COMPANIES_FAIL,
    payload: { code, error },
  };
};

/* SINGLE COMPANY */
// by id
export function getCompanyById(id) {
  return async (dispatch) => {
    try {
      dispatch(getCompanyStarted());
      let company = await companyDAO.getById(id);
      if (company === undefined) {
        dispatch(getCompanyFailed("NO_COMPANY_FOR_ID", new Error()));
      } else {
        dispatch(getCompanyDone(company));
      }
    } catch (error) {
      console.error("getCompanyByIdError", error);
      dispatch(getCompanyFailed("COMPANY_BY_ID_FAIL", error));
    }
  };
}

const getCompanyStarted = () => {
  return {
    type: types.GET_COMPANY_START,
  };
};

const getCompanyDone = (company) => {
  return {
    type: types.GET_COMPANY_END,
    payload: { company },
  };
};

const getCompanyFailed = (code, error) => {
  return {
    type: types.GET_COMPANY_FAIL,
    payload: { code, error },
  };
};

export function updateCompany(id, name, description, url, imageUrl, domain, address, lat, lon) {
  return async (dispatch) => {
    dispatch(updateCompanyStarted());
    try {
      await companyDAO.update(id, name, description, url, imageUrl, domain, address, name.split(" "), lat, lon);
      dispatch(updateCompanyDone());
    } catch (error) {
      console.error(error);
      dispatch(updateCompanyFailed("update failed", error));
    }
    dispatch(getCompanyById(id));
  };
}

const updateCompanyStarted = () => {
  return {
    type: types.UPDATE_COMPANY_START,
  };
};

const updateCompanyDone = () => {
  return {
    type: types.UPDATE_COMPANY_END,
  };
};

const updateCompanyFailed = (code, error) => {
  return {
    type: types.UPDATE_COMPANY_FAIL,
    payload: { code, error },
  };
};
