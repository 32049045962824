import React from "react";
import loadable from "@loadable/component";
import Loading from "../Loading";

const LoadableComponent = loadable(() => import("../../enterprise/Profile"), {
  fallback: (() => <Loading />)(),
});

const Component = () => <LoadableComponent />;

export default Component;
