import * as actionTypes from "../actions/actionTypes";
import { initialState } from "../store";

const errorReducer = (state = initialState.error, action) => {
  switch (action.type) {
    case actionTypes.GLOBAL_ERROR:
      return {
        ...state,
        inError: true,
        error: action.payload.error,
        code: action.payload.code
      };
    case actionTypes.CLEAR_GLOBAL_ERROR:
      return {
        ...state,
        inError: false,
        code: undefined,
        error: undefined
      };
    default:
      return { ...state };
  }
};

export default errorReducer;
