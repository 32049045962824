import firebase from "firebase/app";

import * as commonFirebase from "./commonFirebase";

const collection = () => firebase.firestore().collection("companies");

export const getById = (id) => commonFirebase.getById(collection(), id);

export const search = (search) => commonFirebase.searchStartsWith(collection(), search);

export const update = async (id, name, description, url, imageUrl, domain, address, tags, lat, lon) =>
  await commonFirebase.update(
    collection(),
    id,
    { name, description, url, imageUrl, domain, address, tags, lat, lon },
    true
  );
