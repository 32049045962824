import { useEffect, useState } from "react";
import { saveNotificationTokenOfCurrentUser } from "../dao/user";
// source : https://gist.github.com/rikukissa/cb291a4a82caa670d2e0547c520eae53
// interface IBeforeInstallPromptEvent extends Event {
//   readonly platforms: string[];
//   readonly userChoice: Promise<{
//     outcome: "accepted" | "dismissed";
//     platform: string;
//   }>;
//   prompt(): Promise<void>;
// }

export function useNotificationsPrompt() {
  const [prompt, setPrompt] = useState(null);

  const promptToNotify = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(new Error("Tried calling requestNotifications before checking current status"));
  };

  useEffect(() => {
    if (Notification.permission && Notification.permission !== "granted" && Notification.permission !== "denied") {
      setPrompt({ prompt: requestNotification });
    }
  }, []);

  return [prompt, promptToNotify];
}

const requestNotification = () => {
  checkCloudMessagingTokenAndPermission();
};

/*****************
 * NOTIFICATIONS *
 *****************/
export const checkCloudMessagingTokenAndPermission = () => {
  // Callback fired if Instance ID token is updated.
  try {
    const firebase = global.firebase;
    firebase
      .messaging()
      .getToken()
      .then((currentToken) => {
        if (currentToken !== undefined) saveNotificationTokenOfCurrentUser(currentToken);
      })
      .catch((err) => {
        console.error("An error occurred while retrieving token. ", err);
      });

    firebase.messaging().onTokenRefresh(() => {
      firebase
        .messaging()
        .getToken()
        .then((refreshedToken) => {
          if (refreshedToken !== undefined) saveNotificationTokenOfCurrentUser(refreshedToken);
        })
        .catch((err) => {
          console.error("Unable to retrieve refreshed token ", err);
        });
    });

    firebase.messaging().onMessage((payload) => {
      console.log("Message received. ", payload);
      /*     const example = {
        collapse_key: "campaign_collapse_key_1242303270155746247",
        data: {
          "gcm.n.e": "1",
          "google.c.a.c_id": "1242303270155746247",
          "google.c.a.e": "1",
          "google.c.a.ts": "1589789329",
          "google.c.a.udt": "0",
        },
        from: "104665143606",
        notification: {
          title: "Test for sw",
          body: "Service worker is displaying stuff ?",
          tag: "campaign_collapse_key_1242303270155746247",
          image: "https://jobeez.app/apple-touch-icon-120x120.png",
        },
        priority: "high",
      };

      const { title, body, tag } = payload.notification;

      if (!title) return;

      // ...*/
    });

    Notification.requestPermission().then((permission) => {
      console.log(
        permission === "granted" ? "Notification permission granted." : "Unable to get permission to notify.",
        permission.token
      );
    });
  } catch (error) {
    console.error(error);
  }
};
