// import firebase from "firebase/app";
//import "firebase/auth";
// import "firebase/messaging";
// import "firebase/analytics";
import { push } from "connected-react-router";

import md5 from "../../utils/md5";
import * as types from "../actionTypes";
import { createUser, getUserDocument, updateCurrentUserMD5, updateCurrentUser } from "../../dao/user";
import { changePasswordKnownCurrent, changeEmail, changePasswordLostPassword } from "../../dao/auth";
import { getCompanyById } from "./company";

require("../../firebase/init");
/***************
 * AUTH STATUS *
 ***************/

export const checkFirebaseAuth = async (dispatch, initPath) => {
  const firebase = global.firebase;
  const path = await new Promise((resolve) => {
    dispatch(firebaseAuthStarted());
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userDocument = await getUserDocument(user.uid);
        if (userDocument.type === "ENTERPRISE") dispatch(getCompanyById(userDocument.companyId));
        dispatch(loginDone(user.uid, userDocument));
        if (process.env.NODE_ENV === "production") firebase.analytics().setUserId(user.uid);
        dispatch(firebaseAuthDone());
      } else {
        dispatch(logout(false));
        dispatch(firebaseAuthDone());
      }
      if (initPath !== undefined && initPath !== "/") {
        resolve(initPath);
      } else {
        resolve("/");
      }
    });
  });
  dispatch(push(path));
};

const firebaseAuthStarted = () => {
  return {
    type: types.FIREBASE_AUTH_START,
  };
};

const firebaseAuthDone = () => {
  return {
    type: types.FIREBASE_AUTH_END,
  };
};

// LOGIN
export const login = (email, password) => {
  return async (dispatch) => {
    const firebase = global.firebase;
    try {
      dispatch(loginStarted());
      if (!email || !password) dispatch(loginFailed("login/missing-field", new Error()));
      else
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(async (userCredentials) => {
            let userDocument = await getUserDocument(userCredentials.user.uid);
            if (process.env.NODE_ENV === "production") firebase.analytics().setUserId(userCredentials.user.uid);
            if (userDocument.type === "ENTERPRISE") dispatch(getCompanyById(userDocument.companyId));
            dispatch(loginDone(userCredentials.user.uid, userDocument));
            dispatch(push("/"));
          })
          .catch((error) => {
            switch (error.code) {
              case "auth/invalid-email":
              case "auth/user-disabled":
              case "auth/user-not-found":
              case "auth/wrong-password":
              default:
                break;
              // handle other codes ...
            }
            dispatch(loginFailed(error.code, error));
          });
    } catch (error) {
      dispatch(loginFailed("LOGIN_FAIL", error));
    }
  };
};

const loginStarted = () => {
  return {
    type: types.LOGIN_START,
  };
};

const loginDone = (id, userDocument) => {
  return {
    type: types.LOGIN_END,
    payload: { id, userDocument },
  };
};

const loginFailed = (code, error) => {
  return {
    type: types.LOGIN_FAIL,
    payload: { code, error },
  };
};

// LOGOUT
export function logout(goToHome) {
  return async (dispatch) => {
    const firebase = global.firebase;
    dispatch(logoutStarted());
    await firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(logoutDone());
        if (goToHome === true) dispatch(push("/"));
      })
      .catch((error) => {
        dispatch(logoutFailed("LOGOUT_FAIL", error));
      });
  };
}
const logoutStarted = () => {
  return {
    type: types.LOGOUT_START,
  };
};

const logoutDone = () => {
  return {
    type: types.LOGOUT_END,
  };
};

const logoutFailed = (code, error) => {
  return {
    type: types.LOGIN_FAIL,
    payload: { code, error },
  };
};

/********************
 * REGISTER STUDENT *
 ********************/
// EMAIL
export const registerEmailStudent = (email, password, passwordConf, firstName, lastName, city, domain, level) => {
  return async (dispatch) => {
    dispatch(registerEmailStudentStarted());
    if (
      email === "" ||
      password === "" ||
      passwordConf === "" ||
      firstName === "" ||
      lastName === "" ||
      domain === "" ||
      level === ""
    ) {
      dispatch(registerEmailStudentFailed("missing-mandatory-field"), new Error());
    } else if (password !== passwordConf) {
      dispatch(registerEmailStudentFailed("bad-pw-conf"), new Error());
    } else {
      const firebase = global.firebase;
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async (userCredentials) => {
          await createUser(userCredentials.user.uid, "STUDENT", md5(email), firstName, lastName, city, domain, level);
          if (process.env.NODE_ENV === "production") firebase.analytics().setUserProperties({ level, domain });
          dispatch(registerEmailStudentSucceeded());
          dispatch(push("/"));
        })
        .catch((error) => {
          dispatch(registerEmailStudentFailed(error.code, error));
        });
    }
  };
};

const registerEmailStudentStarted = () => {
  return {
    type: types.REGISTER_STUDENT_EMAIL_START,
  };
};

const registerEmailStudentSucceeded = () => {
  return {
    type: types.REGISTER_STUDENT_EMAIL_SUCCESS,
  };
};

const registerEmailStudentFailed = (code, error) => {
  return {
    type: types.REGISTER_STUDENT_EMAIL_FAIL,
    payload: { code, error },
  };
};

/*******************
 * CHANGE PASSWORD *
 ********************/
export const changeUserPassword = (currentPassword, newPassword, newPasswordConfirmation) => {
  return async (dispatch, getState) => {
    dispatch(updateProfileStarted());
    if (newPassword !== newPasswordConfirmation) {
      dispatch(updateProfileFailed("password/badconf", new Error("new pass and conf are different")));
    } else {
      try {
        await changePasswordKnownCurrent(currentPassword, newPassword, newPasswordConfirmation);
        dispatch(updateProfileSucceeded(await getUserDocument(getState().auth.userId)));
      } catch (error) {
        dispatch(updateProfileFailed("password/daoerr", error));
      }
    }
  };
};
export const changeUserEmail = (newEmail, currentPassword) => {
  return async (dispatch, getState) => {
    dispatch(updateProfileStarted());
    try {
      await changeEmail(newEmail, currentPassword);
      const m = md5(newEmail);
      await updateCurrentUserMD5(m);
      dispatch(updateProfileSucceeded(await getUserDocument(getState().auth.userId)));
      dispatch(md5Update(m));
    } catch (error) {
      dispatch(updateProfileFailed("email/daoerr", error));
    }
  };
};

export const changeStudentUser = (firstName, lastName, city, domain, level) => {
  return async (dispatch, getState) => {
    dispatch(updateProfileStarted());
    if (firstName === "" || lastName === "" || domain === "" || level === "") {
      dispatch(updateProfileFailed("profilestu/missing-mandatory-field"), new Error());
    } else {
      try {
        await updateCurrentUser(firstName, lastName, city, domain, level);
        if (process.env.NODE_ENV === "production")
          global.firebase.analytics().setUserProperties({ level: level, domain: domain });

        dispatch(updateProfileSucceeded(await getUserDocument(getState().auth.userId)));
      } catch (error) {
        dispatch(updateProfileFailed("profilestu/daoerr", error));
      }
    }
  };
};
export const changeEnterpriseUser = (firstName, lastName) => {
  return async (dispatch, getState) => {
    dispatch(updateProfileStarted());
    if (firstName === "" || lastName === "") {
      dispatch(updateProfileFailed("profileent/missing-mandatory-field"), new Error());
    } else {
      try {
        await updateCurrentUser(firstName, lastName);
        dispatch(updateProfileSucceeded(await getUserDocument(getState().auth.userId)));
      } catch (error) {
        dispatch(updateProfileFailed("profileent/daoerr", error));
      }
    }
  };
};

const updateProfileStarted = () => {
  return {
    type: types.UPDATE_PROFILE_START,
  };
};

const updateProfileSucceeded = (userDocument) => {
  return {
    type: types.UPDATE_PROFILE_END,
    payload: { userDocument },
  };
};

const updateProfileFailed = (code, error) => {
  return {
    type: types.UPDATE_PROFILE_FAIL,
    payload: { code, error },
  };
};

const md5Update = (md5) => {
  return {
    type: types.MD5_UPDATE,
    payload: { md5 },
  };
};

/*************************
 * LOST PASSWORD ACTIONS *
 *************************/
export const requestLostPasswordEmail = (email) => {
  return async (dispatch) => {
    dispatch(requestLostPasswordEmailStarted());
    if (email === "") {
      dispatch();
    } else {
      global.firebase
        .auth()
        .sendPasswordResetEmail(email, { url: "http://localhost:3000" })
        .then(() => {
          dispatch(requestLostPasswordEmailSucceeded());
        })
        .catch((error) => {
          console.error(error);
          dispatch(requestLostPasswordEmailFailed(error.code, error));
        });
    }
  };
};

const requestLostPasswordEmailStarted = () => {
  return {
    type: types.REQUEST_RESET_PASSWORD_START,
  };
};

const requestLostPasswordEmailSucceeded = () => {
  return {
    type: types.REQUEST_RESET_PASSWORD_END,
  };
};

const requestLostPasswordEmailFailed = (code, error) => {
  return {
    type: types.REQUEST_RESET_PASSWORD_FAIL,
    payload: { code, error },
  };
};

export const resetPasswordWithCode = (code, password, passwordConf) => {
  return async (dispatch) => {
    if (password !== passwordConf) {
      dispatch(resetPasswordFailed("resetpass/badconf", new Error()));
    } else {
      dispatch(resetPasswordStarted());
      try {
        await changePasswordLostPassword(code, password);
        dispatch(resetPasswordSucceeded());
      } catch (e) {
        console.error(e);
        dispatch(resetPasswordFailed(e.code || "resetpass/daoerr", e));
      }
    }
  };
};

const resetPasswordStarted = () => {
  return {
    type: types.RESET_PASSWORD_START,
  };
};

const resetPasswordSucceeded = () => {
  return {
    type: types.RESET_PASSWORD_END,
  };
};

const resetPasswordFailed = (code, error) => {
  return {
    type: types.RESET_PASSWORD_FAIL,
    payload: { code, error },
  };
};
